'use client';

import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { getCookie } from '@/utils/cookieManager';
import { GEOLOCATION_STORE_COOKIE } from '@/constants/cookies';

interface IStoreSwitcherSettings {
    resolvedStoreGeolocationCode: string;
    resolvedStoreGeolocationCountry: string;
}

const StoreSwitcherContext = createContext<IStoreSwitcherSettings>({} as IStoreSwitcherSettings);

export const StoreSwitcherProvider = ({ children, fallback }: { children: ReactElement; fallback?: ReactElement }) => {
    const cookieValue = getCookie(GEOLOCATION_STORE_COOKIE);
    const [geolocationCountry, setGeolocationCountry] = useState<string>(cookieValue || '');

    const fetchGeolocation = async () => {
        const response = await fetch('/api/user-geo-location');
        const data = await response.json();
        setGeolocationCountry(data.store);
    };

    useEffect(() => {
        if (!geolocationCountry) {
            fetchGeolocation();
        }
    }, []);

    if (!geolocationCountry) return fallback || null;

    return (
        <StoreSwitcherContext.Provider
            value={{
                resolvedStoreGeolocationCode: geolocationCountry,
                resolvedStoreGeolocationCountry: geolocationCountry.split('_')[1],
            }}
        >
            {children}
        </StoreSwitcherContext.Provider>
    );
};

export const useStoreSwitcherContext = () => useContext(StoreSwitcherContext);
